._K0-OE {
  position: relative; }

._3GlSf {
  visibility: hidden;
  color: #fff;
  text-align: center;
  border-radius: .6rem;
  padding: .54rem;
  position: absolute;
  top: 2rem;
  z-index: 60;
  left: 50%;
  font-size: 1.5rem;
  opacity: 0;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.9), 0 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.9);
  word-wrap: break-word; }

#_XdVEO {
  background-color: #a00000;
  border: 0.2rem solid rgba(200, 0, 0, 0.4);
  min-width: 30rem;
  max-width: 30rem;
  margin-left: -15rem; }

#_XdVEO._1_99M,
#_3FiSe._1_99M {
  visibility: visible;
  -webkit-animation: _aG2tf 0.5s;
          animation: _aG2tf 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#_XdVEO._dxHdm,
#_3FiSe._dxHdm {
  visibility: hidden;
  -webkit-animation: _3DYJ8 0.5s;
          animation: _3DYJ8 0.5s;
  transition: visibility 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes _aG2tf {
  from {
    top: -5rem;
    opacity: 0; }
  to {
    top: 2rem;
    opacity: .98; } }

@keyframes _aG2tf {
  from {
    top: -5rem;
    opacity: 0; }
  to {
    top: 2rem;
    opacity: .98; } }

@-webkit-keyframes _3DYJ8 {
  from {
    top: 2rem;
    opacity: .98; }
  to {
    top: -5rem;
    opacity: 0; } }

@keyframes _3DYJ8 {
  from {
    top: 2rem;
    opacity: .98; }
  to {
    top: -5rem;
    opacity: 0; } }

._1SQ0g {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  color: #e5e5e5;
  background-color: #444;
  opacity: .6;
  padding: 5px;
  margin: 2px;
  font-size: 1.5rem; }

._6miOA {
  margin: 0 .6rem 0 0;
  padding: .2rem 1.2rem .2rem .8rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  border-radius: .25rem;
  background: white;
  border: .2rem solid white;
  color: black;
  white-space: nowrap;
  cursor: pointer;
  outline: none; }
  ._6miOA > img {
    width: 1.8rem;
    height: 1.8rem; }
  ._6miOA > div {
    padding-left: .25rem; }
  @media (hover) {
    ._6miOA:hover:not(:focus) {
      opacity: .6;
      transition: 0.5s; } }
  ._6miOA:focus {
    outline: none;
    background-color: red;
    border-color: red;
    color: white;
    transition: 0.25s; }
  ._6miOA:active {
    outline: none; }

._24S2R {
  -webkit-animation: _3VZYe .5s;
          animation: _3VZYe .5s; }

@-webkit-keyframes _3VZYe {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes _3VZYe {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media only screen and (max-width: 864px) {
  ._24S2R {
    max-height: 90vw; } }

@media only screen and (max-width: 1152px) and (min-width: 864px) {
  ._24S2R {
    max-height: 75vw; } }

@media only screen and (min-width: 1920px) {
  ._24S2R {
    max-height: 1080px; } }

._JuOW5 {
  font-size: 2rem;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: black;
  z-index: 50;
  opacity: 0.85;
  -webkit-animation: _lL_Wr .5s;
          animation: _lL_Wr .5s; }

@-webkit-keyframes _lL_Wr {
  from {
    opacity: 0; }
  to {
    opacity: .85; } }

@keyframes _lL_Wr {
  from {
    opacity: 0; }
  to {
    opacity: .85; } }

._3vLjH {
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #000000;
  opacity: .9;
  z-index: 100; }
  ._3SNG1 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    transform: translate(-50%, -50%); }
  ._1uezp {
    padding: 0 2rem 0 2rem;
    line-height: 140%; }
  ._2_lRq {
    padding-top: 2rem;
    display: flex;
    justify-content: center; }

._2hN9h {
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px; }
  ._1qZf2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    transform: translate(-50%, -50%); }
  .__1qPk {
    font-size: 2rem;
    padding-bottom: 2rem; }
  ._lDZev {
    padding: 0 2rem 0 2rem;
    line-height: 140%; }
  ._1Pc-x {
    padding-top: 2rem;
    display: flex;
    justify-content: center; }

._1EdfC {
  position: absolute;
  display: flex;
  z-index: 49;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  align-items: center;
  pointer-events: none; }
  ._2fzXq {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    position: relative; }
    ._3kAeI {
      padding: 2rem; }
      ._3_mWk {
        margin: 0;
        padding: .6rem 1rem;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        line-height: 2rem;
        border-radius: .25rem;
        border: transparent;
        background-color: white;
        color: black;
        white-space: nowrap;
        cursor: pointer;
        outline: none; }
        ._bXm8E {
          width: 2rem;
          height: 2rem; }
        ._1s7E8 {
          overflow: hidden;
          opacity: 1;
          max-width: 20em;
          transition: opacity .5s, max-width .75s ease-out; }
          ._3UVe4 {
            padding-left: 0.5rem; }
        ._1rLRd {
          opacity: 0 !important;
          max-width: 0 !important; }

._23LJF {
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 51; }
  ._QxYEW {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    max-height: 80vh;
    min-height: 80vh;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: rgba(9, 9, 9, 0.8); }
  ._1OdS7 {
    font-size: 2rem;
    font-weight: 700;
    padding: 1rem 0 1rem 0;
    color: white;
    opacity: 0; }
    ._2k8Sx {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto; }
      ._R64Gp {
        margin-left: 1rem; }
      ._AqRKW {
        height: 3rem; }
      ._17LMl {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        border: 0;
        padding: .2rem;
        margin: 1rem 3rem;
        line-height: 1rem;
        border-radius: .9rem;
        background-color: transparent;
        color: white;
        cursor: pointer;
        outline: none;
        overflow: hidden;
        opacity: .7; }
        ._17LMl > img {
          width: 3rem;
          height: 3rem; }
        ._17LMl:focus {
          outline: none;
          background-color: #292929;
          transition: 0.5s;
          opacity: .8;
          transform: scale(1.3); }
        @media (hover) {
          ._17LMl:hover {
            outline: none;
            background-color: #292929;
            transition: 0.5s;
            opacity: .8;
            transform: scale(1.3); }
          ._17LMl:hover:focus {
            opacity: .75;
            transition: 0.5s;
            background-color: #191919;
            transform: scale(1.3); } }
        ._17LMl:active {
          outline: none; }
  ._3aKyj {
    line-height: 140%;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 2.5rem;
    text-align: center;
    flex-grow: 1;
    opacity: 0; }
    ._1ytqs {
      display: inline-block; }
  ._E4PtH {
    padding: 1rem 0 1.5rem 0;
    display: flex;
    justify-content: center; }
    ._E4PtH > button {
      margin-right: .5rem;
      margin-left: .5rem; }
    ._E4PtH > button:hover:not(:focus) {
      transition: none; }
    ._E4PtH > button:focus {
      transition: none; }

._1KfgM {
  opacity: 1;
  transition: opacity .5s ease-in; }

._3LSfo {
  opacity: 0;
  cursor: default; }
  ._3LSfo:focus {
    opacity: 0; }
  @media (hover) {
    ._3LSfo:hover {
      opacity: 0; }
    ._3LSfo:hover:focus {
      opacity: 0; } }

@-webkit-keyframes _1ebv0 {
  from {
    opacity: 0; }
  to {
    opacity: .8; } }

@keyframes _1ebv0 {
  from {
    opacity: 0; }
  to {
    opacity: .8; } }

._36jkv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

._1YZKt {
  width: 20vw;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: .5rem;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: #bbb; }

._2Sb1H {
  height: 4rem; }

._3e512 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

._2J1_d {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1rem;
  align-items: right;
  text-align: right;
  justify-content: center;
  font-size: 1.6rem;
  color: #bbb; }

._1FOsd {
  width: 45vw;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1rem;
  align-items: left;
  text-align: left;
  justify-content: center; }

._35dTO {
  position: relative;
  display: inline-block;
  width: 4.4rem;
  height: 2.4rem; }
  ._fAfxL {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 1;
    border-radius: 4rem;
    border: .2rem solid #444; }
  ._fAfxL:before {
    position: absolute;
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    left: .25rem;
    bottom: .25rem;
    background-color: #DDD;
    border-radius: 50%;
    transition: .2s; }
  ._35dTO input {
    opacity: 0;
    width: 0;
    height: 0; }
  input:checked + ._fAfxL:before {
    background-color: red; }
  input:focus + ._fAfxL {
    outline: none !important;
    box-shadow: 0 0 .8rem #aaa;
    transition: 0.25s;
    outline: none !important;
    border: .2rem solid red; }
  input:checked + ._fAfxL {
    background-color: #DDD; }
  input:checked:not(:focus) + ._fAfxL {
    border: .2rem solid #000; }
  input:checked + ._fAfxL:before {
    transform: translateX(2rem); }

._1O0HI {
  position: absolute;
  display: flex;
  z-index: 51;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  align-items: center; }
  ._2KOe- {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    position: relative; }
    ._3VdT0 {
      display: flex;
      flex-direction: column;
      padding: 2rem; }
      ._2kozc {
        font-size: 2.3rem;
        font-weight: 700;
        color: white; }
      ._xdCUz {
        font-size: 1.5rem;
        font-weight: 600;
        color: #777777; }
    ._zmLiu {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 60;
      font-size: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      ._3c68d {
        display: flex;
        flex-direction: row;
        justify-content: center; }
  ._27F_L {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border: 0;
    padding: .7rem;
    margin: 1.3rem;
    width: 9rem;
    height: 9rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    border-radius: .9rem;
    background-color: transparent;
    color: white;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    opacity: .7; }
    ._27F_L > img {
      width: 3.5rem;
      height: 3.5rem; }
    ._27F_L > div {
      height: 4.5rem;
      overflow: hidden;
      text-overflow: ellipsis; }
    ._27F_L:focus {
      outline: none;
      background-color: #292929;
      transition: 0.5s;
      opacity: .8;
      transform: scale(1.2); }
    @media (hover) {
      ._27F_L:hover {
        outline: none;
        background-color: #292929;
        transition: 0.5s;
        opacity: .8;
        transform: scale(1.2); }
      ._27F_L:hover:focus {
        opacity: .75;
        transition: 0.5s;
        background-color: #191919;
        transform: scale(1.2); } }
    ._27F_L:active {
      outline: none; }

._PRCqq {
  position: absolute;
  display: flex;
  z-index: 48;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  align-items: center;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-animation: _3AE1- 1s;
          animation: _3AE1- 1s; }
  ._joVTu {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); }
    ._pMNFn {
      width: 5rem;
      display: block;
      margin: auto; }
    ._3GuSO {
      font-size: 1.6rem;
      color: white;
      margin-top: 1rem;
      opacity: 0.7; }

@-webkit-keyframes _3AE1- {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes _3AE1- {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

._Hgflf {
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px; }
  ._ZPrBo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    transform: translate(-50%, -50%); }
  ._2ZPd7 {
    font-size: 2rem;
    padding-bottom: 2rem; }
  ._32Wda {
    padding: 0 2rem 0 2rem;
    line-height: 140%; }
  ._JMXJp {
    padding-top: 2rem; }
  ._37wua {
    padding-top: 2rem;
    display: flex;
    justify-content: center; }
    ._37wua > button {
      margin-right: 1rem; }

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap");
html,
body {
  font-family: 'Quicksand', sans-serif;
  font-size: 1vw;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%; }

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  color: #e5e5e5; }

button, input {
  font-family: 'Quicksand', sans-serif; }

a {
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  color: #e5e5e5;
  transition: color .4s; }

a:hover {
  color: #aaaaaa; }

a:focus {
  color: #ce2f37; }

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; }

canvas {
  display: block;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: pixelated;
  image-rendering: crisp-edges; }

._3o3pD {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 2.5rem;
  -webkit-animation: _26Bvn 1s;
          animation: _26Bvn 1s;
  z-index: 5; }

._1sp2T {
  font-family: 'Roboto Mono', monospace;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 10rem;
  font-weight: 300;
  -webkit-animation: _26Bvn 1s;
          animation: _26Bvn 1s;
  color: #252525; }

@-webkit-keyframes _26Bvn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes _26Bvn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media only screen and (max-width: 864px) {
  html {
    font-size: 1.3vw; } }

@media only screen and (max-width: 1152px) and (min-width: 864px) {
  html {
    font-size: 1.2vw; } }

@media only screen and (min-width: 1920px) {
  html {
    font-size: 19.2px; } }

._24Yg1 {
  font-size: 1.4rem;
  background: #090909;
  color: #ccc;
  border: .2rem solid #444;
  border-radius: .4rem;
  padding: .2rem .4rem; }
  ._24Yg1:focus {
    outline: none !important;
    border: .2rem solid red;
    box-shadow: 0 0 .8rem #aaa; }

/* custom scrollbar */
::-webkit-scrollbar {
  width: 22px;
  height: 22px; }

::-webkit-scrollbar-track {
  background-color: transparent; }

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box; }

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf; }

::-webkit-scrollbar-corner {
  background-color: transparent; }
